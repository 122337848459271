.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* fix primereact styles that conflict with bronson */
a.c-page-head-nav__link {
  color: #004666 !important;
}
a.c-stepper-nav__link {
  color: #004666 !important;
}

div.c-upload-list__title-row {
  display:flex !important;
}
/* primereact fix for dialogs that are not scrollable otherwise */
div.p-dialog-content {
  overflow:auto
}
.p-datatable table {
  margin-bottom: auto;
}
.p-datatable {
  line-height: 1.0;
}

/* this is from Bronson application */
.o-page-wrap {
  max-width: calc(1920px + 80px);
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

.c-header__content-wrapper {
  max-width: calc(1920px + 80px);
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  height: 68px;
  position: relative;
}